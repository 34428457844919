import type { FunctionComponent } from "react"

import dynamic from "next/dynamic"
import Image from "next/image"
import { useState } from "react"

import youtubePlayIcon from "@/modules/corporate/images/youtube_play_icon.png"

import styles from "./MediaVideo.module.css"

export type MediaVideoProps = {
  title: string
  url: string
}

const YOUTUBE_URL_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtube\.com\/embed\/|youtu\.be\/)([a-zA-Z0-9_-]{11})/

const DynamicVideoPlayer = dynamic(
  () => import("@jobteaser/ui-kit/components/VideoPlayer/VideoPlayer").then(mod => mod.VideoPlayer),
  { ssr: false }
)

export const MediaVideo: FunctionComponent<MediaVideoProps> = ({ title, url }) => {
  const youtubeVideoUrlMatch = url.match(YOUTUBE_URL_REGEX)

  const thumbnailSrc =
    !!youtubeVideoUrlMatch && `https://img.youtube.com/vi_webp/${youtubeVideoUrlMatch[1]}/maxresdefault.webp`
  const [showVideo, setShowVideo] = useState(!youtubeVideoUrlMatch)

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        if (!showVideo) {
          setShowVideo(true)
        }
      }}
      onKeyDown={() => {
        if (!showVideo) {
          setShowVideo(true)
        }
      }}
      role="button"
      tabIndex={0}
    >
      {thumbnailSrc && (
        <>
          {!showVideo && (
            <Image alt="Play the video" className={styles.icon} height={48} priority src={youtubePlayIcon} width={68} />
          )}
          <figure className={styles.thumbnailWrapper}>
            <Image
              alt={title}
              className={styles.thumbnail}
              priority
              sizes="100vw"
              src={thumbnailSrc}
              /* hq random 16 / 9 size, resize is done after on css side */
              height="540"
              width="960"
            />
          </figure>
        </>
      )}
      {showVideo && (
        <div className={styles.videoWrapper}>
          <DynamicVideoPlayer playing url={url} className={styles.video} />
        </div>
      )}
    </div>
  )
}
